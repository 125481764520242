@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Kanit:ital,wght@0,200;0,700;1,200&family=Oswald:wght@200;300;400;500;600;700&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-carousel .carousel .slide {
  width: 300px;
  /* Customize the width as per your needs */
  height: 300px;
  /* Customize the height as per your needs */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.custom-carousel .custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: red;
}

.custom-carousel .custom-arrow-left {
  left: 10px;
  /* Customize the left position as per your needs */
}

.custom-carousel .custom-arrow-right {
  right: 10px;
  /* Customize the right position as per your needs */
}



