.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 20px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    /* width: 50%; */
    /* height: 50%; */
    object-fit: cover;
  }
  /* Navigation Buttons */
.swiper-button-next, .swiper-button-prev {
    color: rgb(0, 0, 0);
    background-color: white; /* for rectangular background */
}

/* Pagination */
.swiper-pagination-bullet {
    background: rgb(0, 0, 0);
}


